
import { Vue, Component } from "vue-property-decorator";
import user from "@/store/modules/UserModule";
import { UserService } from "@/api/UserServiceApi";

@Component
export default class DashboardNavbar extends Vue {
  get companyName(): string {
    return user.companyName;
  }

  async logout() {
    await UserService.LogoutUser();
    user.clear();
    await this.$router.push("/login");
  }
}
